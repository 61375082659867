import { Record, Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, anonRecord_type, lambda_type, bool_type, tuple_type, class_type, unit_type, list_type, string_type, union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { create, SubscriptionCount, value as value_58, SubscriptionCount_$reflection } from "../../../../Logos.Shared/Domain/Entity/SubscriptionCount.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { Product_$reflection } from "../../../../Logos.Shared/Domain/Entity/Product.fs.js";
import { cmdOfPromise, cmdOfFunc, cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getStripeKey } from "../../../AppDomain/UseCase/Stripe.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot as appRoot_1 } from "../../../Infrastructure/AppRoot.fs.js";
import * as stripe$002Ehelper from "../../../public/js/stripe.helper.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { empty, append, ofArray, toArray, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { stripeProductId, SubscriptionDto, getProductIdAndPriceId } from "../../../../Logos.Shared/Domain/Entity/Stripe.fs.js";
import { List_distinct } from "../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { createObj, stringHash } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { LocalUserData } from "../../../AppDomain/SystemService.fs.js";
import { saveUserData } from "../../../AppDomain/UseCase/Auth.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../ViewContainer.fs.js";
import { empty as empty_1, singleton as singleton_2, append as append_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_h5, Daisy_error, Daisy_h2 } from "../Component.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class PaymentStage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SubscriptionCountSelection", "CreditCardEntry"];
    }
}

export function PaymentStage_$reflection() {
    return union_type("Presentation.Component.Stripe.Subscribe.PaymentStage", [], PaymentStage, () => [[], []]);
}

export class StripeMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetAdditionalSubscriptionCount", "ParseAdditionalSubscriptionCount", "StripeKeyFetched", "StripeFormInitialized", "StripeInactiveSubscriptionCreated", "PaymentConfirmed", "Subscribe", "UpdateCaller", "LocalDataUpdated", "SubscriptionPaymentSuccessful", "GotoPaymentStage", "ApiError"];
    }
}

export function StripeMsg_$reflection() {
    return union_type("Presentation.Component.Stripe.Subscribe.StripeMsg", [], StripeMsg, () => [[["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [SubscriptionCount_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", SubscriptionCount_$reflection()]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", unit_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(class_type("System.DateTime"), string_type, string_type, PaymentStage_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(class_type("System.DateTime"), string_type, string_type, PaymentStage_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", PaymentStage_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class StripeState extends Record {
    constructor(Username, PaymentStage, Product, StripeKey, StripePaymentMethodId, Props, IsSubscribing, SubscriptionId, AdditionalSubscriptionCount, Errors) {
        super();
        this.Username = Username;
        this.PaymentStage = PaymentStage;
        this.Product = Product;
        this.StripeKey = StripeKey;
        this.StripePaymentMethodId = StripePaymentMethodId;
        this.Props = Props;
        this.IsSubscribing = IsSubscribing;
        this.SubscriptionId = SubscriptionId;
        this.AdditionalSubscriptionCount = AdditionalSubscriptionCount;
        this.Errors = Errors;
    }
}

export function StripeState_$reflection() {
    return record_type("Presentation.Component.Stripe.Subscribe.StripeState", [], StripeState, () => [["Username", string_type], ["PaymentStage", PaymentStage_$reflection()], ["Product", Product_$reflection()], ["StripeKey", string_type], ["StripePaymentMethodId", string_type], ["Props", anonRecord_type(["onSubscribed", lambda_type(unit_type, unit_type)])], ["IsSubscribing", bool_type], ["SubscriptionId", option_type(string_type)], ["AdditionalSubscriptionCount", SubscriptionCount_$reflection()], ["Errors", list_type(string_type)]]);
}

export function getStripeKeyCmd() {
    return cmdOfAsync((Item) => (new StripeMsg(2, [Item])), (Item_1) => (new StripeMsg(11, [Item_1])), () => singleton.Delay(() => getStripeKey(appRoot_1.StripeService)));
}

export function initStripeForm(stripeKey, clientSecret) {
    return cmdOfFunc(() => (new StripeMsg(3, [undefined])), (Item_1) => (new StripeMsg(11, [Item_1])), () => {
        stripe$002Ehelper.InitStripe(stripeKey, clientSecret);
        stripe$002Ehelper.LoadStripeForm();
        stripe$002Ehelper.MountCardElements();
    });
}

export function confirmPaymentCmd(state) {
    return cmdOfPromise((Item) => (new StripeMsg(5, [Item])), (Item_1) => (new StripeMsg(11, [Item_1])), () => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (stripe$002Ehelper.ConfirmPayment().then((_arg_1) => {
        const result = _arg_1;
        return result.isError ? (Promise.resolve(new FSharpResult$2(1, [singleton_1(result.errorMessage)]))) : (Promise.resolve(new FSharpResult$2(0, [undefined])));
    })))));
}

export function createInactiveStripeSubscriptionCmd(appRoot, product, subscriptionCount, paymentStageAfterProcessing) {
    return cmdOfAsync((Item) => (new StripeMsg(4, [Item])), (Item_1) => (new StripeMsg(11, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1_1 = (value_58(subscriptionCount) > 0) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Subscription count is required and must be greater than 0.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1_2) => {
            const input_9 = input_1_2;
            if (input_9.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_9.fields[0]]));
            }
            else {
                let asyncResult;
                const value_1_2 = getProductIdAndPriceId(product);
                asyncResult = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult, (input_1_1) => {
                    const input_6 = input_1_1;
                    if (input_6.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                    }
                    else {
                        const _arg_2 = input_6.fields[0];
                        const subscriptionDto = new SubscriptionDto(_arg_2[0], _arg_2[1], subscriptionCount);
                        let x_1;
                        const selectedOrgId = AppRootModule_getSelectedOrgId();
                        x_1 = appRoot.StripeService.CreateInactiveSubscription(selectedOrgId, subscriptionDto);
                        return singleton.Bind(x_1, (x$0027) => {
                            let _arg_3;
                            let value_4;
                            const input_2 = x$0027;
                            value_4 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(_arg_3 = input_2.fields[0], [_arg_3[0], _arg_3[1], _arg_3[2], paymentStageAfterProcessing])])));
                            return singleton.Return(value_4);
                        });
                    }
                });
            }
        });
    }));
}

export function markSubscriptionPaymentSuccessfulCmd(state) {
    return cmdOfAsync((Item) => (new StripeMsg(9, [Item])), (Item_1) => (new StripeMsg(11, [Item_1])), () => singleton.Delay(() => {
        const matchValue = state.SubscriptionId;
        if (matchValue == null) {
            return singleton.Return(new FSharpResult$2(1, [singleton_1("Invalid Subscription Id.")]));
        }
        else {
            const x = matchValue;
            const x_1 = appRoot_1.StripeService.MarkSubscriptionPaymentSuccessful(x);
            return singleton.Bind(x_1, (x$0027) => {
                let value;
                const input_2 = x$0027;
                value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(state.Props.onSubscribed(), undefined)])));
                return singleton.Return(value);
            });
        }
    }));
}

export function updateLocalAppDataCmd(product, clientSecret) {
    return cmdOfAsync((Item) => (new StripeMsg(8, [Item])), (Item_1) => (new StripeMsg(11, [Item_1])), () => singleton.Delay(() => {
        let asyncResult;
        const value_1 = appRoot_1.SystemService.Load("UserData");
        asyncResult = singleton.Return(value_1);
        return singleton.Bind(asyncResult, (input_1_2) => {
            let x, list_1;
            const input_7 = input_1_2;
            if (input_7.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_7.fields[0]]));
            }
            else {
                let updateUserData;
                const input_1 = input_7.fields[0];
                updateUserData = ((input_1 == null) ? undefined : ((x = input_1, new LocalUserData(x.Username, x.IsMember, toArray(List_distinct((list_1 = ofArray(x.PurchasedServices), append(singleton_1(stripeProductId(product)), list_1)), {
                    Equals: (x_1, y) => (x_1 === y),
                    GetHashCode: stringHash,
                })), x.Token, x.SelectedOrganisation, x.UserOrganisations))));
                if (updateUserData == null) {
                    return singleton.Return(new FSharpResult$2(0, [clientSecret]));
                }
                else {
                    const x_3 = saveUserData(appRoot_1.SystemService, updateUserData);
                    return singleton.Bind(x_3, (x$0027) => {
                        let value_2;
                        const input_3 = x$0027;
                        value_2 = ((input_3.tag === 1) ? (new FSharpResult$2(1, [input_3.fields[0]])) : (new FSharpResult$2(0, [clientSecret])));
                        return singleton.Return(value_2);
                    });
                }
            }
        });
    }));
}

export function init(product, props) {
    return [new StripeState("", new PaymentStage(0, []), product, "", "", props, false, undefined, new SubscriptionCount(1), empty()), getStripeKeyCmd()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 3:
            return [state, Cmd_none()];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, false, state.SubscriptionId, state.AdditionalSubscriptionCount, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new StripeState(state.Username, msg.fields[0].fields[0][3], state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, false, msg.fields[0].fields[0][2], state.AdditionalSubscriptionCount, state.Errors), updateLocalAppDataCmd(state.Product, msg.fields[0].fields[0][1])];
            }
        case 6:
            return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, true, state.SubscriptionId, state.AdditionalSubscriptionCount, state.Errors), confirmPaymentCmd(state)];
        case 0:
            return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, empty()), cmdOfFunc((Item) => (new StripeMsg(1, [Item])), (Item_1) => (new StripeMsg(11, [Item_1])), () => create(parseInt(msg.fields[0], 0)))];
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, msg.fields[0].fields[0], state.Errors), Cmd_none()];
            }
        case 7:
            return [state, Cmd_none()];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, initStripeForm(state.StripeKey, msg.fields[0].fields[0])];
            }
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, false, state.SubscriptionId, state.AdditionalSubscriptionCount, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, markSubscriptionPaymentSuccessfulCmd(state)];
            }
        case 9:
            if (msg.fields[0].tag === 1) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, false, state.SubscriptionId, state.AdditionalSubscriptionCount, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        case 10:
            if (value_58(state.AdditionalSubscriptionCount) === 0) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, singleton_1("Subscription count is required and must be greater than 0.")), Cmd_none()];
            }
            else if (msg.fields[0].tag === 1) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, true, state.SubscriptionId, state.AdditionalSubscriptionCount, state.Errors), createInactiveStripeSubscriptionCmd(appRoot_1, state.Product, state.AdditionalSubscriptionCount, new PaymentStage(1, []))];
            }
            else {
                return [new StripeState(state.Username, msg.fields[0], state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, state.Errors), Cmd_none()];
            }
        case 11:
            return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, state.StripeKey, state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new StripeState(state.Username, state.PaymentStage, state.Product, msg.fields[0].fields[0], state.StripePaymentMethodId, state.Props, state.IsSubscribing, state.SubscriptionId, state.AdditionalSubscriptionCount, state.Errors), Cmd_none()];
            }
    }
}

export function IndexView(indexViewInputProps) {
    const props = indexViewInputProps.props;
    const product = indexViewInputProps.product;
    let patternInput;
    const init_1 = init(product, props);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container(toList(delay(() => append_1(singleton_2(Daisy_h2("Credit card details for subscription")), delay(() => append_1((state_1.PaymentStage.tag === 1) ? append_1(singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", join(" ", ["mr-4"])], ["className", "btn-primary"], ["disabled", state_1.IsSubscribing], ["className", "btn-sm"], ["children", "Back"], ["onClick", (_arg_3) => {
        dispatch(new StripeMsg(10, [new PaymentStage(0, [])]));
    }]]))))), delay(() => singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append_1(state_1.IsSubscribing ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append_1(singleton_2(["className", "btn-secondary"]), delay(() => append_1(singleton_2(["disabled", state_1.IsSubscribing]), delay(() => append_1(singleton_2(["className", "btn-sm"]), delay(() => append_1(singleton_2(["children", "Pay"]), delay(() => singleton_2(["onClick", (_arg_4) => {
        dispatch(new StripeMsg(6, []));
    }]))))))))))))))))))) : singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["disabled", state_1.IsSubscribing], ["className", "btn-sm"], ["children", "Next"], ["onClick", (_arg_2) => {
        dispatch(new StripeMsg(10, [new PaymentStage(1, [])]));
    }]]))))), delay(() => {
        let children_9;
        return singleton_2((children_9 = toList(delay(() => append_1(singleton_2(Daisy_error(state_1.Errors)), delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => {
            let children_7, children_5;
            return (state_1.PaymentStage.tag === 1) ? append_1(singleton_2(Daisy_h5(`Subscription count: ${value_58(state_1.AdditionalSubscriptionCount)}`)), delay(() => singleton_2(createElement("div", {
                id: "payment",
            })))) : singleton_2((children_7 = ofArray([(children_5 = singleton_1(createElement("span", {
                className: "label-text",
                children: "Subscription count",
            })), createElement("label", {
                className: "label",
                children: reactApi.Children.toArray(Array.from(children_5)),
            })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", value_58(state_1.AdditionalSubscriptionCount)], ["autoFocus", true], ["placeholder", "Subscription count"], ["onChange", (ev) => {
                dispatch(new StripeMsg(0, [ev.target.value]));
            }]]))))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_7)),
            })));
        })))))), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children_9)),
        })));
    })))))));
}

