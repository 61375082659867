import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, tuple_type, record_type, string_type, list_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Product as Product_2, ProductSubscription_$reflection } from "../../../../Logos.Shared/Domain/Entity/Product.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Result_traverse, AsyncResult_bindOfResult } from "../../../../Logos.Shared/Util.fs.js";
import { ofArray, singleton as singleton_1, empty, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { ProductSubscriptionDtoModule_toProductSubscription } from "../../../../Logos.Shared/Domain/Dto/Product.fs.js";
import { isProductSubscribed } from "../../../AppDomain/UseCase/Stripe.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Page, Cmd_navigateToPage } from "../../Router.fs.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { empty as empty_1, map as map_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_h6, Daisy_h5, Daisy_error, Daisy_h2 } from "../../Component/Component.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { subscriptionBar } from "../../Component/Stripe/SubscriptionBar.fs.js";
import { IndexView as IndexView_1 } from "../../Component/Stripe/SubscriptionUserAllocation.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(IsSignedIn, WorkMateProductSubscriptions, WorkMateMobileProductSubscriptions, IsSubscribing, IsCheckingSubscription, Errors) {
        super();
        this.IsSignedIn = IsSignedIn;
        this.WorkMateProductSubscriptions = WorkMateProductSubscriptions;
        this.WorkMateMobileProductSubscriptions = WorkMateMobileProductSubscriptions;
        this.IsSubscribing = IsSubscribing;
        this.IsCheckingSubscription = IsCheckingSubscription;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.Product.WorkMate.State", [], State, () => [["IsSignedIn", bool_type], ["WorkMateProductSubscriptions", list_type(ProductSubscription_$reflection())], ["WorkMateMobileProductSubscriptions", list_type(ProductSubscription_$reflection())], ["IsSubscribing", bool_type], ["IsCheckingSubscription", bool_type], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GotoSignup", "SubscriptionStatus", "GetProductSubscriptionsResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Product.WorkMate.Msg", [], Msg, () => [[], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(ProductSubscription_$reflection()), list_type(ProductSubscription_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(ProductSubscription_$reflection()), list_type(ProductSubscription_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function isProductSubscribedCmd() {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        const asyncResult = AsyncResult_bindOfResult((arg) => Result_traverse(map(ProductSubscriptionDtoModule_toProductSubscription, arg)), isProductSubscribed(appRoot.StripeService, AppRootModule_getSelectedOrgId(), new Product_2(2, [])));
        return singleton.Bind(asyncResult, (input_1_1) => {
            const input_6 = input_1_1;
            if (input_6.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
            }
            else {
                const x_4 = AsyncResult_bindOfResult((arg_1) => Result_traverse(map(ProductSubscriptionDtoModule_toProductSubscription, arg_1)), isProductSubscribed(appRoot.StripeService, AppRootModule_getSelectedOrgId(), new Product_2(3, [])));
                return singleton.Bind(x_4, (x$0027) => {
                    let value;
                    const input_2 = x$0027;
                    value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [[input_6.fields[0], input_2.fields[0]]])));
                    return singleton.Return(value);
                });
            }
        });
    }));
}

export function init(systemService) {
    let x;
    let isSignedIn;
    const input_1 = systemService.Load("UserData");
    isSignedIn = ((input_1.tag === 1) ? (new FSharpResult$2(1, [input_1.fields[0]])) : (new FSharpResult$2(0, [(x = input_1.fields[0], (x == null) ? false : (x.Token != null))])));
    const emptyState = new State(false, empty(), empty(), false, true, empty());
    if (isSignedIn.tag === 1) {
        return [new State(emptyState.IsSignedIn, emptyState.WorkMateProductSubscriptions, emptyState.WorkMateMobileProductSubscriptions, emptyState.IsSubscribing, emptyState.IsCheckingSubscription, isSignedIn.fields[0]), Cmd_none()];
    }
    else {
        const x_2 = isSignedIn.fields[0];
        return [new State(x_2, emptyState.WorkMateProductSubscriptions, emptyState.WorkMateMobileProductSubscriptions, emptyState.IsSubscribing, emptyState.IsCheckingSubscription, empty()), x_2 ? isProductSubscribedCmd() : Cmd_none()];
    }
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.IsSignedIn, state.WorkMateProductSubscriptions, state.WorkMateMobileProductSubscriptions, !msg.fields[0], state.IsCheckingSubscription, state.Errors), msg.fields[0] ? isProductSubscribedCmd() : Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsSignedIn, empty(), empty(), state.IsSubscribing, false, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.IsSignedIn, msg.fields[0].fields[0][0], msg.fields[0].fields[0][1], state.IsSubscribing, false, state.Errors), Cmd_none()];
            }
        case 3:
            return [new State(state.IsSignedIn, state.WorkMateProductSubscriptions, state.WorkMateMobileProductSubscriptions, state.IsSubscribing, state.IsCheckingSubscription, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [state, Cmd_navigateToPage(new Page(19, []))];
    }
}

export function PricingTable_PricingTable(pricingTable_PricingTableInputProps) {
    let elems_11, elems_10, elems_4, elems_9;
    const dispatch = pricingTable_PricingTableInputProps.dispatch;
    const state = pricingTable_PricingTableInputProps.state;
    return createElement("div", createObj(ofArray([["className", "pricing-table container mx-auto my-8 p-4"], (elems_11 = [createElement("h4", {
        className: "text-3xl font-bold text-center mb-8",
        children: "Pricing Plans",
    }), createElement("div", createObj(ofArray([["className", "grid grid-cols-1 md:grid-cols-2 gap-8"], (elems_10 = [createElement("div", createObj(ofArray([["className", "pricing-plan border rounded-lg shadow-lg p-6 bg-white text-center"], (elems_4 = toList(delay(() => append(singleton_2(createElement("h4", {
        className: "text-2xl font-semibold mb-4",
        children: "WorkMate",
    })), delay(() => {
        let value_19;
        return append(singleton_2(createElement("p", createObj(ofArray([["className", "text-gray-600 mb-4 h-16 overflow-hidden"], ["style", {
            display: "block",
            overflow: "auto",
        }], (value_19 = "For back office users to manage timesheets, invoicing, payroll export, and cost allocation with ease.", ["children", value_19])])))), delay(() => {
            let elems;
            return append(singleton_2(createElement("p", createObj(ofArray([["className", "price mb-4"], (elems = [createElement("span", {
                className: "text-4xl font-bold",
                children: "$50",
            }), createElement("span", {
                className: "text-sm text-gray-600",
                children: "NZD per user/month Ex GST",
            })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_3;
                return append(singleton_2(createElement("ul", createObj(ofArray([["className", "list-none mb-4"], (elems_3 = toList(delay(() => map_1((feature) => {
                    let elems_2, elems_1;
                    return createElement("li", createObj(ofArray([["className", "flex items-center justify-start mb-2"], (elems_2 = [createElement("span", createObj(ofArray([["className", "text-green-500 mr-2"], (elems_1 = [createElement("i", {
                        className: "fas fa-check",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("span", {
                        children: [feature],
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
                }, ["Manage Timesheets", "Payroll integration with Smartly™", "Invoicing integration with Xero™", "Cost allocation with Simpro™", "Reporting", "Data export"]))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => (!state.IsSignedIn ? singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary w-full py-2"], ["children", "Subscribe"], ["onClick", (_arg) => {
                    dispatch(new Msg(0, []));
                }]]))))) : empty_1())));
            }));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", "pricing-plan border rounded-lg shadow-lg p-6 bg-white text-center"], (elems_9 = toList(delay(() => append(singleton_2(createElement("h4", {
        className: "text-2xl font-semibold mb-4",
        children: "WorkMate Mobile",
    })), delay(() => {
        let value_61;
        return append(singleton_2(createElement("p", createObj(ofArray([["className", "text-gray-600 mb-4 h-16 overflow-hidden"], ["style", {
            display: "block",
            overflow: "auto",
        }], (value_61 = "Capture and manage employee shift data on mobile devices to streamline and automate the generation of timesheets.", ["children", value_61])])))), delay(() => {
            let elems_5;
            return append(singleton_2(createElement("p", createObj(ofArray([["className", "price mb-4"], (elems_5 = [createElement("span", {
                className: "text-4xl font-bold",
                children: "$7",
            }), createElement("span", {
                className: "text-sm text-gray-600",
                children: "NZD per user/month Ex GST",
            })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                let elems_8;
                return append(singleton_2(createElement("ul", createObj(ofArray([["className", "list-none mb-4"], (elems_8 = toList(delay(() => map_1((feature_1) => {
                    let elems_7, elems_6;
                    return createElement("li", createObj(ofArray([["className", "flex items-center justify-start mb-2"], (elems_7 = [createElement("span", createObj(ofArray([["className", "text-green-500 mr-2"], (elems_6 = [createElement("i", {
                        className: "fas fa-check",
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("span", {
                        children: [feature_1],
                    })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])));
                }, ["Capture Shift Data", "Automated Sync", "Shift Pre-checks", "Multiple organizations", "Leave Management", "Shift location tracking"]))), ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => (!state.IsSignedIn ? singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary w-full py-2"], ["children", "Subscribe"], ["onClick", (_arg_1) => {
                    dispatch(new Msg(0, []));
                }]]))))) : empty_1())));
            }));
        }));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", reactApi.Children.toArray(Array.from(elems_11))])])));
}

export function IndexView() {
    let elems_5, elems_1, elems;
    let patternInput;
    const init_1 = init(appRoot.SystemService);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(singleton_1((elems_5 = [createElement("div", createObj(Helpers_combineClasses("hero", ofArray([["className", "min-h-[20vh]"], ["className", "bg-base-200"], ["style", {
        backgroundImage: "url(\'./image/WorkMate/FeatureGraphic.png\')",
    }], (elems_1 = [createElement("div", {
        className: "hero-overlay bg-opacity-60",
    }), createElement("div", createObj(Helpers_combineClasses("hero-content", ofArray([["className", "text-center text-neutral-content"], (elems = [Daisy_h2("WorkMate")], ["children", reactApi.Children.toArray(Array.from(elems))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), container(toList(delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => append(state_1.IsSignedIn ? append(singleton_2(viewBox("WorkMate web subscriptions", new TitleSize(0, []), ofArray([createElement(subscriptionBar, {
        product: new Product_2(2, []),
        isSignedIn: state_1.IsSignedIn,
        productSubscriptions: state_1.WorkMateProductSubscriptions,
        isCheckingSubscription: state_1.IsCheckingSubscription,
        props: {
            OnSubscribed: (isFinished) => {
                dispatch(new Msg(1, [isFinished]));
            },
            Product: new Product_2(2, []),
        },
    }), createElement("br", {}), createElement(IndexView_1, {
        Product: new Product_2(2, []),
    })]))), delay(() => {
        let elems_2;
        return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["pt-16"])], (elems_2 = [viewBox("WorkMate mobile app subscriptions", new TitleSize(0, []), ofArray([createElement(subscriptionBar, {
            product: new Product_2(3, []),
            isSignedIn: state_1.IsSignedIn,
            productSubscriptions: state_1.WorkMateMobileProductSubscriptions,
            isCheckingSubscription: state_1.IsCheckingSubscription,
            props: {
                OnSubscribed: (isFinished_1) => {
                    dispatch(new Msg(1, [isFinished_1]));
                },
                Product: new Product_2(3, []),
            },
        }), createElement("br", {}), createElement(IndexView_1, {
            Product: new Product_2(3, []),
        })]))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => singleton_2(createElement("br", {}))));
    })) : empty_1(), delay(() => append(singleton_2(Daisy_h5("Streamline Timesheet Management and Invoicing with Ease")), delay(() => {
        let value_15;
        return append(singleton_2(createElement("p", createObj(singleton_1((value_15 = "WorkMate is your ultimate solution for simplifying timesheet management, invoicing, \n                        payroll export, and cost allocation within your organization. Say goodbye to the hassle of manual data \n                        entry and complex payroll processes. With WorkMate, you can effortlessly capture shift data on \n                        mobile devices and transform it into automated timesheet runs, invoicing processes, and seamless \n                        payroll exports to widely used platforms like Smartly™, Xero™, and cost allocation with Simpro™. \n                        Please note that Smartly™, Xero™, and Simpro™ are trademarks of their respective owners and are \n                        independent platforms that WorkMate integrates with.", ["children", value_15]))))), delay(() => append(singleton_2(Daisy_h6("Key Features:")), delay(() => {
            let elems_3, value_18, value_20, value_22, value_24, value_26, value_28;
            return append(singleton_2(createElement("ol", createObj(ofArray([["className", join(" ", ["list-decimal", "list-inside"])], (elems_3 = [createElement("li", createObj(singleton_1((value_18 = "Mobile Shift Data Capture: Our intuitive mobile app allows employees to \n                                easily input their shift data on the go, ensuring accurate and timely records.", ["children", value_18])))), createElement("li", createObj(singleton_1((value_20 = "Automated Timesheet Runs: Say farewell to manual timesheet creation. \n                                WorkMate automates the entire process, saving you valuable time and reducing errors.", ["children", value_20])))), createElement("li", createObj(singleton_1((value_22 = "Efficient Invoicing: Seamlessly generate invoices based on captured shift \n                                data, streamlining your billing process and ensuring prompt payments.", ["children", value_22])))), createElement("li", createObj(singleton_1((value_24 = "Seamless Payroll Export: Export payroll data effortlessly to Smartly™ and Xero™, \n                                ensuring smooth integration with your existing payroll systems.", ["children", value_24])))), createElement("li", createObj(singleton_1((value_26 = "Cost Allocation with Simpro™: Automatically allocate labour costs to specific \n                                projects or cost centers within Simpro™, enhancing your financial tracking and project \n                                management capabilities.", ["children", value_26])))), createElement("li", createObj(singleton_1((value_28 = "Customizable Reporting: Gain valuable insights into your workforce with reporting \n                                features, empowering you to make informed decisions.", ["children", value_28]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => append(singleton_2(Daisy_h6("Why Choose WorkMate?")), delay(() => {
                let elems_4, value_32, value_34, value_36;
                return append(singleton_2(createElement("ol", createObj(ofArray([["className", join(" ", ["list-decimal", "list-inside"])], (elems_4 = [createElement("li", createObj(singleton_1((value_32 = "WorkMate streamlines timesheet management, invoicing, payroll export, and cost allocation, \n                                allowing you to focus on what matters most—your business.", ["children", value_32])))), createElement("li", createObj(singleton_1((value_34 = "Accuracy: Say goodbye to errors caused by manual data entry. WorkMate \n                                ensures precise records, payments, and payroll exports every time.", ["children", value_34])))), createElement("li", createObj(singleton_1((value_36 = "Accessibility: With our mobile app, your employees can easily input shift \n                                data from anywhere, anytime, making the process seamless and convenient.", ["children", value_36]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => append(singleton_2(Daisy_h6("Ready to Transform Your Timesheet Management, Invoicing, Payroll Export, and Cost Allocation?")), delay(() => {
                    let value_39;
                    return append(singleton_2(createElement("p", createObj(singleton_1((value_39 = "Take the first step towards simplifying your timesheet management, invoicing, payroll export, \n                        and cost allocation processes with WorkMate. Join organizations already benefiting from our comprehensive and \n                        innovative solution.", ["children", value_39]))))), delay(() => append(singleton_2(Daisy_h6("Get Started Today")), delay(() => {
                        let value_41;
                        return append(singleton_2(createElement("p", createObj(singleton_1((value_41 = "Ready to experience the power of WorkMate? Sign up now to streamline your timesheet management, \n                        invoicing, payroll export, and cost allocation processes. Purchase licenses for your organization and start \n                        revolutionizing the way you work.", ["children", value_41]))))), delay(() => singleton_2(createElement(PricingTable_PricingTable, {
                            state: state_1,
                            dispatch: dispatch,
                        }))));
                    }))));
                }))));
            }))));
        }))));
    })))))))))], ["children", reactApi.Children.toArray(Array.from(elems_5))]))));
}

